import { Meta } from "@solidjs/meta";
import { createAsync, query } from "@solidjs/router";
import { For, Show } from "solid-js";

import { gql } from "~/__gql-generated__";
import { SocialType, UserRole } from "~/__gql-generated__/graphql";
import postStyles from "~/components/Post.module.scss";
import rootStyles from "~/components/Root.module.scss";
import SmartA from "~/components/SmartA";
import Title from "~/components/Title";
import styles from "~/components/UserPage.module.scss";
import categoryStyles from "~/routes/colunas.module.scss";
import { generateWidthThumbs } from "~/utils/generateThumbs";
import { client } from "~/utils/graphql";

const roleLabels: Record<UserRole, Record<string, string>> = {
  [UserRole.Super]: {
    MALE: "Superadministrador",
    FEMALE: "Superadministradora",
    HIDDEN: "Superadministrador(a)",
  },
  [UserRole.Admin]: {
    MALE: "Administrador",
    FEMALE: "Administradora",
    HIDDEN: "Administrador(a)",
  },
  [UserRole.Audio]: {
    MALE: "Áudio e Plástica",
    FEMALE: "Áudio e Plástica",
    HIDDEN: "Áudio e Plástica",
  },
  [UserRole.AudioAdmin]: {
    MALE: "Adm. Áudio",
    FEMALE: "Adm. Áudio",
    HIDDEN: "Adm. Áudio",
  },
  [UserRole.Author]: {
    MALE: "Redator",
    FEMALE: "Redatora",
    HIDDEN: "Redator(a)",
  },
  [UserRole.AuthorAdmin]: {
    MALE: "Adm. Redação",
    FEMALE: "Adm. Redação",
    HIDDEN: "Adm. Redação",
  },
  [UserRole.Dj]: {
    MALE: "DJ",
    FEMALE: "DJ",
    HIDDEN: "DJ",
  },
  [UserRole.DjAdmin]: {
    MALE: "Admin. DJs",
    FEMALE: "Admin. DJs",
    HIDDEN: "Admin. DJs",
  },
  [UserRole.Pr]: {
    MALE: "Relações Públicas",
    FEMALE: "Relações Públicas",
    HIDDEN: "Relações Públicas",
  },
  [UserRole.PrAdmin]: {
    MALE: "Admin. RP",
    FEMALE: "Admin. RP",
    HIDDEN: "Admin. RP",
  },
};

export const socialLabels: Record<SocialType, string> = {
  [SocialType.Bluesky]: "Bluesky",
  [SocialType.Custom]: "Link",
  [SocialType.Deviantart]: "DeviantArt",
  [SocialType.Facebook]: "Facebook",
  [SocialType.Flickr]: "Flickr",
  [SocialType.Github]: "GitHub",
  [SocialType.Instagram]: "Instagram",
  [SocialType.Linkedin]: "LinkedIn",
  [SocialType.Psn]: "PlayStation Network",
  [SocialType.Skype]: "Skype",
  [SocialType.Soundcloud]: "SoundCloud",
  [SocialType.Steam]: "Steam",
  [SocialType.Tiktok]: "TikTok",
  [SocialType.Tumblr]: "Tumblr",
  [SocialType.Twitch]: "Twitch",
  [SocialType.Twitter]: "X",
  [SocialType.XboxLive]: "Xbox Live",
  [SocialType.Youtube]: "YouTube",
};

function zodiacSign(epochTimestamp?: number) {
  const signs = [
    "\u2648",
    "\u2649",
    "\u264A",
    "\u264B",
    "\u264C",
    "\u264D",
    "\u264E",
    "\u264F",
    "\u2650",
    "\u2651",
    "\u2652",
    "\u2653",
  ];

  const timestamp = epochTimestamp ?? Date.now();

  const n = timestamp / 86_400_000 - 10_957.5; // adjustment to Julian calendar, epoch 1/1/2000
  const L = 280.46 + 0.985_647_4 * n;
  const g = ((357.528 + 0.985_600_3 * n) / 180) * Math.PI;

  let result = L + 1.915 * Math.sin(g) + 0.02 * Math.sin(2 * g);
  while (result < 0) {
    result += 360;
  }
  while (result >= 360) {
    result -= 360;
  }

  const index = Math.floor(result / 30);
  return `${signs[index]}\uFE0F`;
}

const PROFILE = gql(`
  query Profile($id: ID!) {
    users(where: { id: $id }) {
      roles
      createdAt
      categories(options: { sort: [{ title: ASC }] }) {
        id
        title
        slug
        genre
        cover {
          url
        }
      }
      radioShows(
        where: { deletedAt: null }
        options: { sort: [{ title: ASC }] }
      ) {
        id
        title
        slug
        genre
        cover {
          url
        }
      }
      profile {
        nickname
        realName
        slug
        picture {
          url
        }
        background {
          url
        }
        gender
        birthdate
        location
        ship
        occupation
        blurb
        customTitle
        nicknameBackground
        hobbies
        musicTastes
        likes
        dislikes
        animeTastes
        doramaTastes
        gameTastes
        mostActiveSocial
        favAsianCulture
        countriesToGo
        favFood
        favPerson
        phrase
        socialLinks {
          type
          link
        }
      }
    }
  }
`);

const getProfile = query(async (id: string) => {
  "use server";

  const { data } = await client.query({ query: PROFILE, variables: { id } });

  const picture = data.users[0].profile?.picture.url;
  const background = data.users[0].profile?.background?.url;

  return {
    ...data.users[0],
    pictureSet: picture ? generateWidthThumbs(picture, 1) : undefined,
    backgroundSet: background ? generateWidthThumbs(background, 1) : undefined,
  };
}, "profile");

interface UserPageProps {
  readonly id: string;
}

function mapRoles(roles: UserRole[] = [], gender = "HIDDEN") {
  return roles.map((role) => roleLabels[role][gender]).join(" · ");
}

export default function UserPage(props: UserPageProps) {
  const user = createAsync(() => getProfile(props.id));
  const title = () => `Página pessoal de ${user()?.profile?.nickname ?? ""}`;

  return (
    <article class={styles.article}>
      <Title>{title()}</Title>
      <Meta property="og:image" content={user()?.profile?.picture.url} />
      <div
        classList={{
          [postStyles.hero]: true,
          [styles["user-page"]]: true,
          [styles.fallback]: !user()?.profile?.background?.url,
        }}
      >
        <img
          classList={{
            [postStyles["hero-image"]]: true,
            [styles["hero-image"]]: true,
          }}
          src={user()?.profile?.background?.url ?? user()?.profile?.picture.url}
          srcSet={user()?.backgroundSet ?? user()?.pictureSet}
          sizes="auto"
          alt=""
        />
        <div class={styles.user}>
          <div class={styles.avatar}>
            <img
              src={user()?.profile?.picture.url}
              srcSet={user()?.pictureSet}
              sizes="auto"
              alt=""
            />
          </div>
          <div class={styles.social}>
            <For each={user()?.profile?.socialLinks}>
              {(link) => {
                const adjustedLink = () => {
                  return link.type === SocialType.Skype &&
                    !link.link.startsWith("skype:")
                    ? `skype:${encodeURIComponent(link.link)}`
                    : link.link;
                };

                return (
                  <SmartA href={adjustedLink()}>
                    <img
                      src={`/assets/brands/${link.type.toLowerCase()}/dark.svg`}
                      alt={socialLabels[link.type]}
                    />
                  </SmartA>
                );
              }}
            </For>
          </div>
          <h1>{user()?.profile?.nickname}</h1>
          <p>{user()?.profile?.customTitle}</p>
          <p class={styles.roles}>
            {mapRoles(user()?.roles, user()?.profile?.gender)}
          </p>
        </div>
      </div>
      <div class={postStyles.body}>
        <Show when={user()?.profile?.phrase}>
          <blockquote>
            <p>{user()?.profile?.phrase}</p>
          </blockquote>
        </Show>
        <ul class={styles.info}>
          <Show when={user()?.profile?.realName}>
            <li>
              <b>Nome verdadeiro:</b> {user()?.profile?.realName}
            </li>
          </Show>
          <Show when={user()?.profile?.birthdate}>
            <li>
              <b>Nasceu a:</b>{" "}
              {new Date(user()?.profile?.birthdate).toLocaleString(
                import.meta.env.VITE_LOCALE,
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                },
              )}{" "}
              {zodiacSign(new Date(user()?.profile?.birthdate).getTime())}
            </li>
          </Show>
          <Show when={user()?.profile?.location}>
            <li>
              <b>Estado de relacionamento:</b> {user()?.profile?.ship}
            </li>
          </Show>
          <Show when={user()?.profile?.location}>
            <li>
              <b>Mora em:</b> {user()?.profile?.location}
            </li>
          </Show>
          <Show when={user()?.createdAt}>
            <li>
              <b>Na J-Hero desde:</b>{" "}
              {new Date(user()?.createdAt).toLocaleString(
                import.meta.env.VITE_LOCALE,
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                },
              )}
            </li>
          </Show>
        </ul>
        <h2>Mais sobre {user()?.profile?.nickname}</h2>
        <Show when={user()?.profile?.blurb}>
          <p>{user()?.profile?.blurb}</p>
        </Show>
        <Show when={user()?.profile?.occupation}>
          <p>
            <b>Profissão:</b>
            <br />
            {user()?.profile?.occupation}
          </p>
        </Show>
        <Show when={user()?.profile?.nicknameBackground}>
          <p>
            <b>
              Historia do <i lang="en">nick</i>:
            </b>
            <br />
            {user()?.profile?.nicknameBackground}
          </p>
        </Show>
        <Show when={user()?.profile?.hobbies}>
          <p>
            <b>
              <i lang="en">Hobbies</i>:
            </b>
            <br />
            {user()?.profile?.hobbies}
          </p>
        </Show>
        <Show when={user()?.profile?.mostActiveSocial}>
          <p>
            <b>Redes sociais em que aparece mais:</b>
            <br />
            {user()?.profile?.mostActiveSocial}
          </p>
        </Show>
        <Show when={user()?.profile?.favAsianCulture}>
          <p>
            <b>Que parte da cultura asiática gosta mais?</b>
            <br />
            {user()?.profile?.favAsianCulture}
          </p>
        </Show>
        <Show when={user()?.profile?.countriesToGo}>
          <p>
            <b>Que lugares da cultura asiática quer conhecer?</b>
            <br />
            {user()?.profile?.countriesToGo}
          </p>
        </Show>
        <Show when={user()?.profile?.musicTastes}>
          <p>
            <b>Três bandas ou artistas:</b>
            <br />
            {user()?.profile?.musicTastes}
          </p>
        </Show>
        <Show when={user()?.profile?.animeTastes}>
          <p>
            <b>Três animes:</b>
            <br />
            {user()?.profile?.animeTastes}
          </p>
        </Show>
        <Show when={user()?.profile?.doramaTastes}>
          <p>
            <b>
              Três <i lang="ja-Latn">doramas</i>:
            </b>
            <br />
            {user()?.profile?.doramaTastes}
          </p>
        </Show>
        <Show when={user()?.profile?.gameTastes}>
          <p>
            <b>
              Três <i lang="en">games</i>:
            </b>
            <br />
            {user()?.profile?.gameTastes}
          </p>
        </Show>
        <Show when={user()?.profile?.favFood}>
          <p>
            <b>Comida favorita:</b>
            <br />
            {user()?.profile?.favFood}
          </p>
        </Show>
        <Show when={user()?.profile?.likes}>
          <p>
            <b>Três coisas que gosta:</b>
            <br />
            {user()?.profile?.likes}
          </p>
        </Show>
        <Show when={user()?.profile?.dislikes}>
          <p>
            <b>
              Três coisas que <em>não</em> gosta:
            </b>
            <br />
            {user()?.profile?.dislikes}
          </p>
        </Show>
        <Show when={user()?.categories.length}>
          <h3>Colunas de {user()?.profile?.nickname}</h3>
          <ul class={categoryStyles.list}>
            <For each={user()?.categories}>
              {(category) => (
                <li>
                  <div class={categoryStyles.image}>
                    <img
                      loading="lazy"
                      sizes="auto"
                      src={category.cover.url}
                      srcSet={generateWidthThumbs(category.cover.url, 1)}
                      alt=""
                    />
                  </div>
                  <div class={categoryStyles.meta}>
                    <SmartA
                      class={rootStyles.pseudo}
                      href={`/${category.slug}`}
                    >
                      {category.title}
                    </SmartA>
                    <p>{category.genre}</p>
                  </div>
                </li>
              )}
            </For>
          </ul>
        </Show>
        <Show when={user()?.radioShows.length}>
          <h3>Programas de {user()?.profile?.nickname}</h3>
          <ul class={categoryStyles.list}>
            <For each={user()?.radioShows}>
              {(radioShow) => (
                <li>
                  <div class={categoryStyles.image}>
                    <img
                      loading="lazy"
                      sizes="auto"
                      src={radioShow.cover.url}
                      srcSet={generateWidthThumbs(radioShow.cover.url, 1)}
                      alt=""
                    />
                  </div>
                  <div class={categoryStyles.meta}>
                    <SmartA
                      class={rootStyles.pseudo}
                      href={`/grade#__${radioShow.slug}`}
                    >
                      {radioShow.title}
                    </SmartA>
                    <p>{radioShow.genre}</p>
                  </div>
                </li>
              )}
            </For>
          </ul>
        </Show>
      </div>
    </article>
  );
}
